<template>
  <div class="hero master-w">
    <div class="hero-body">
      <p
        class="title"
        v-bind:class="{ 'is-1': !isMobile(), 'is-3': isMobile() }"
      >
        Modifier les Prix
      </p>
      <div class="item-w promo-w">
        <div>
          <p v-if="isPromoActive.active" class="title">Promotion Activée</p>
          <p v-else-if="!isPromoActive.active" class="title">Promotion Désactivée</p>
          <p v-else class="title">Chargement..</p>
        </div>
        <div>
          <button
            v-if="!isPromoActive.active"
            @click="activatePromotion('activate')"
            class="button is-large is-danger"
          >
            Activer les promotions
          </button>
          <button
            v-else-if="isPromoActive.active"
            @click="activatePromotion('deactivate')"
            class="button is-large is-danger"
          >
            Désactiver les promotions
          </button>
          <p v-else class="title">Chargement..</p>
        </div>
      </div>
      <transition name="fade">
        <div class="card-w">
          <div v-for="room in rooms" :key="room.id">
            <div v-if="room.name" class="item-w">
              <div class="title-w">
                <div>
                  <p class="title has-text-left room-name">
                    {{ room.name }}
                  </p>
                  <p class="type">{{ room.type }}</p>
                </div>
                <div class="prices">
                  <p class="title">
                    <span>Prix Standard: </span>{{ room.priceS }} €
                  </p>
                  <p class="title second">
                    <span>Prix Pratiqué: </span>{{ room.price }} €
                  </p>
                </div>
              </div>
              <input
                class="input is-large first-input"
                type="number"
                v-model="inputsStandard[`${room.id}`]"
                placeholder="Prix Standard"
              />
              <input
                class="input is-large"
                type="number"
                v-model="inputsReal[`${room.id}`]"
                placeholder="Prix pratiqué"
              />
              <button
                @click="
                  updatePrice(
                    room.id,
                    parseInt(inputsStandard[`${room.id}`]),
                    parseInt(inputsReal[`${room.id}`])
                  )
                "
                class="button is-large is-warning"
              >
                ENVOYER
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { db } from "../firebase";

export default {
  data() {
    return {
      rooms: [],
      inputsStandard: {},
      inputsReal: {},
      isPromoActive: {},
    };
  },
  firestore() {
    return {
      rooms: db.collection("chambres"),
      isPromoActive: db.collection("infos").doc("promotions"),
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async activatePromotion(action) {
      if (action == "activate") {
        await db
          .doc("infos/promotions")
          .update({ active: true })
          .then(function (doc) {
            console.log(doc, "promo activated");
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (action == "deactivate") {
        await db
          .doc("infos/promotions")
          .update({ active: false })
          .then(function (doc) {
            console.log(doc, "promo deactivated");
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        console.log("error");
      }
    },
    async updatePrice(id, priceS, price) {
      if (price && !priceS) {
        await db
          .collection("chambres")
          .doc(id)
          .update({
            price: price,
          })
          .then(() => {
            console.log("SUCCES");
          });
      } else if (priceS && !price) {
        await db
          .collection("chambres")
          .doc(id)
          .update({
            priceS: priceS,
          })
          .then(() => {
            console.log("SUCCES");
          });
      } else if (price && priceS) {
        await db
          .collection("chambres")
          .doc(id)
          .update({
            price: price,
            priceS: priceS,
          })
          .then(() => {
            console.log("SUCCES");
          });
      } else if (!price && !priceS) {
        alert("remplir les champs");
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.item-w.promo-w {
  margin-bottom: 30px;
}
.item-w.promo-w p.title {
  text-transform: uppercase;
}

article.tile {
  background-color: white;
  box-shadow: 0px 7px 19px 5px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding-bottom: 60px;
}
.tile .subtitle {
  margin-bottom: 70px;
}
.price-input-w {
  padding: 20px;
  /* background-color: rgb(241, 241, 241); */
  margin-bottom: 15px;
  border-radius: 20px;
}
.master-w {
  height: calc(100vh - 70px);
  overflow: scroll;
}
.title span {
  font-weight: 400;
  font-size: 0.8em;
}
.input-w {
  display: flex;
}

.first-input {
  /* margin-right: 20px; */
}

.input {
  margin-top: 20px;
}

button {
  margin-top: 20px;
  font-weight: 600;
  width: 100%;
}
.type {
  text-transform: uppercase;
  font-size: 1em;
  margin: 0;
  margin-bottom: 10px;
  margin-right: 20px;
  width: 120px;
  padding: 10px;
  background-color: rgb(227, 244, 255);
  font-weight: 700;
  color: rgb(0, 102, 255);
  border-radius: 7px;
}
.title-w {
  display: flex;
}

.prices {
  flex: auto;
  margin-bottom: 20px;
}

.prices .title {
  margin: 0;
  text-align: right;
  font-size: 1.7em;
}

.room-name {
  text-transform: uppercase;
  font-size: 1.8em;
}

.title.second {
  margin-top: 32px;
}

.card-w {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px 30px;
  grid-template-areas:
    ". . ."
    ". . ."
    ". . ."
    ". . ."
    ". . .";
}

.item-w {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 50px;
  -webkit-box-shadow: 10px 25px 94px -38px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 10px 25px 94px -38px rgba(0, 0, 0, 0.17);
  box-shadow: 10px 25px 94px -38px rgba(0, 0, 0, 0.17);
}

@media only screen and (max-width: 768px) {
  .card-w {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 30px 30px;
    grid-template-areas:
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      "."
      ".";
  }

  .item-w {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 24px;
    -webkit-box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02);
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02);
  }

  .hero-body {
    padding: 10px;
  }

  .title-w {
    display: block;
  }
  .title.second {
    margin-top: 0px;
  }

  .prices .title {
    margin: 0;
    text-align: left;
    font-size: 1.7em;
  }

  .prices {
    margin-bottom: 5px;
  }
  .item-w {
    position: relative;
  }
  .type {
    position: absolute;
    right: 4px;
    top: 24px;
  }
  .room-name {
    font-size: 1.6em;
  }
}
</style>